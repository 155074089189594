import React from 'react'
import { Tag } from 'antd'

const RegionTag = ({ region }) => {
  switch (region) {
    case 'east':
      return (<Tag color='green'>EAST</Tag>)
    case 'northeast':
      return (<Tag color='purple'>NORTHEAST</Tag>)
    case 'central':
      return (<Tag color='orange'>CENTRAL</Tag>)
    case 'north':
      return (<Tag color='blue'>NORTH</Tag>)
    case 'west':
      return (<Tag color='red'>WEST</Tag>)
    default:
      return;
  }
}

export default RegionTag