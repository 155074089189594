import React from 'react';
import { Avatar, Button, Card, List, Skeleton, Statistic, Tag, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import { gMapLinkParser } from '../../utils/helper';

const MapCard = ({ cars, carpark, card, setCard }) => {
  const S3_BASE_URL = 'https://shariot-file-storage-prod.s3-ap-southeast-1.amazonaws.com/';

  const { address } = carpark;
  const { postalCode, detailAddress, latitude, longtitude: longitude } = address;
  const breakpoint = useBreakpoint();

  let gMapsOpts = { count: 1, locations: [{ latitude, longitude }] };
  // if (searchLocation) {
  //   gMapsOpts = { 
  //     count: 2, 
  //     locations: [
  //       { latitude: searchLocation.latitude, longitude: searchLocation.longitude }, 
  //       { latitude, longitude }
  //     ] 
  //   };
  // };

  // functions
  const onCardClose = () => {
    setCard({ carpark: {}, visibility: false })
  }

  // card properties
  const title = (
    <React.Fragment>
      <a href={gMapLinkParser(gMapsOpts)} target='_blank' rel='noreferrer'>{detailAddress}</a> 
      <small style={{display: 'block', color: 'rgba(0, 0, 0, 0.45)'}}>Singapore {postalCode}</small>
      {/* searchLocation && route ? <small style={{display: 'block', color: 'rgba(0, 0, 0, 0.45)'}}>{route.time} minutes</small> : ''*/}
    </React.Fragment>
  );
  const extra = (
  <Button 
    onClick={onCardClose}
    type='primary' 
    shape='circle' 
    icon={<CloseOutlined />} />
  );

  // list
  const listDataSource = cars.filter(car => car.vehicleCarPark.carPark.uuid === carpark.uuid).sort((a, b) => a.lotNo - b.lotNo);

  const listItem = item => {
    const imageUrl = S3_BASE_URL + item.photoKey;
    const avatar = item.photoKey !== 'fake-photo' ? <Avatar src={imageUrl} /> : <Skeleton.Image />;
    const title = (<Tooltip title={'Shariot ' + item.category}>{item.vehicleName}</Tooltip>);
    const description = (<Tag color='#333333' style={{fontFamily: 'LicensePlate', fontSize: '1rem'}}>{item.plateNumber}</Tag>);
    return (
      <List.Item>
        <List.Item.Meta
          avatar={avatar}
          title={title}
          description={description} />
        <Statistic title='Deck' value={item.deckNo} style={{textAlign: 'center'}} />
        <Statistic title='Lot' value={item.lotNo} style={{textAlign: 'center', marginLeft: '15px'}} />
      </List.Item>
    );
  };

  // styling
  let style = {
    display: card.visibility ? 'block' : 'none',
    position: 'absolute',
    zIndex: 99,
    borderRadius: 15
  };
  if (breakpoint.md) {
    style.width = '400px';
    style.height = '300px';
    style.right = '15px';
    style.bottom = '25px';
  } else if (breakpoint.sm || breakpoint.xs) {
    style.width = '100%';
    style.height = 'auto';
    style.right = 'auto';
    style.bottom = '0';
  }

  let bodyStyle = {
    height: 220,
    overflow: 'scroll'
  }

  if (breakpoint.sm || breakpoint.xs) {
    bodyStyle.height = 200
  }
  
  if (breakpoint.md) {
    bodyStyle.height = 220
  }

  return (
    <Card
      title={title}
      extra={extra}
      style={style}
      bodyStyle={bodyStyle}>
        <List 
          itemLayout='horizontal'
          dataSource={listDataSource}
          renderItem={listItem} />
    </Card>
  )
};

export default MapCard;