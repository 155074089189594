import React, { useState, useEffect } from 'react'
import { Layer, Feature } from 'react-mapbox-gl'

import { getPetrolStations } from '../../../utils/petrolStation';

const PetrolFeature = () => {

  const [stations, setStations] = useState([]);

  useEffect(() => {
    async function fetchStations() {
      const petrolStations = await getPetrolStations();
      setStations(petrolStations);
    }
    fetchStations();
  }, []);

  // station features
  const getStationFeature = (name) => {
    return stations.filter(station => station.company === name).map(station => {
      return (
        <Feature 
          key={station.name} 
          coordinates={[station.longitude, station.latitude]} />
      );
    });
  }

  return (
    <>
      <Layer 
        type='symbol' 
        layout={{ 'icon-image': 'spc-icon', 'icon-size': 0.3 }}
        id='petrol_station'>
        {getStationFeature('SPC')}
      </Layer>
      <Layer 
        type='symbol' 
        layout={{ 'icon-image': 'shell-icon', 'icon-size': 0.3 }}
        id='petrol_station_2'>
        {getStationFeature('Shell')}
      </Layer>
      <Layer 
        type='symbol' 
        layout={{ 'icon-image': 'esso-icon', 'icon-size': 0.3 }}
        id='petrol_station_3'>
        {getStationFeature('Esso')}
      </Layer>
      <Layer 
        type='symbol' 
        layout={{ 'icon-image': 'caltex-icon', 'icon-size': 0.3 }}
        id='petrol_station_4'>
        {getStationFeature('Caltex')}
      </Layer>
    </>
  )
}

export default PetrolFeature