import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Typography, Layout, Grid, Row, Col, Button, Select, Alert } from 'antd';
import { ReloadOutlined, LoadingOutlined } from '@ant-design/icons';

import VehicleTable from './tables/VehicleTable';
import RegionTable from './tables/RegionTable';

import LoadingCar from '../LoadingCar';

const { Title } = Typography;
const { Content } = Layout;
const { Option } = Select;
const { useBreakpoint } = Grid;

const AllVehicle = ({ cars, refreshCarHandler }) => {

  const breakpoint = useBreakpoint();

  // abstracted functions
  // const shariotDataHandler = () => {
  //   return new Promise((resolve, reject) => {
  //     getShariotData().then(cars => {
  //       setCars(cars);
  //       resolve(cars);
  //     }, err => {
  //       console.log(err);
  //       message.error('There has been an issue retrieving the data, try again!');
  //       reject(err);
  //     })
  //   });
  // };

  // states 
  // const [cars, setCars] = useState([]);
  const [motion, setMotion] = useState({ refresh: false });
  const [viewBy, setViewBy] = useState('vehicle');

  // effects
  // useEffect(() => {
  //   shariotDataHandler();
  // }, []);

  // page handlers 
  const onRefreshHandler = async () => {
    // setCars([]);
    // setMotion({ refresh: true });
    // await shariotDataHandler();
    // setMotion({ refresh: false });
    setMotion({ refresh: true });
    await refreshCarHandler();
    setMotion({ refresh: false });
  };

  // variables 
  let table = (
    <React.Fragment>
      <Row justify='center' style={{ padding: '15px', paddingBottom: 0 }}>
        <LoadingCar />
      </Row>
      <Row justify='center' style={{ position: 'relative', top: '-50px', fontWeight: 500 }}>
        Loadin' up cars
      </Row>
    </React.Fragment>
    );

  // logic 
  if (Object.keys(cars).length !== 0) {
    if (viewBy === 'vehicle') table = <VehicleTable cars={cars} breakpoint={breakpoint} />
    if (viewBy === 'region') table = <RegionTable cars={cars} breakpoint={breakpoint} />
  }

  return (
    <Content style={{ padding: '20px 30px' }}>
      <Row>
        <Col span={12} xs={{ span: 24 }}>
          <Alert
            message="Thank you & good bye :("
            description="Shariot Explorer will be phased out from 31 March 2023. Thank you for your continuous support &amp; I hope to see all of you again soon! Goodbye. - Samuel"
            type="info"
            showIcon
            style={{ marginBottom: 20 }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} xs={{ span: 24 }}>
          <Title style={{marginBottom: 0}}>
            All Vehicles
          </Title>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <Title level={3} style={{marginTop: 0}}>
            {cars.length > 0 ? (<span>Total cars: {cars.length}</span>) : ''}
          </Title>
        </Col>
        <Col md={{ span: 12, offset: 0 }} xs={{ span: 0 }}>
          <Row justify='end'>
            <span style={{ marginRight: 5, marginTop: 5 }}>View by:</span>
            <Select defaultValue='vehicle' onChange={(value) => setViewBy(value)} value={viewBy} style={{ width: 150, marginRight: 20 }}>
              <Option value='vehicle'>Vehicle Model</Option>
              <Option value='region'>Region</Option>
            </Select>
            <Button type='primary' onClick={onRefreshHandler}>
              {motion.refresh ? <LoadingOutlined /> : <ReloadOutlined />}
            </Button>
          </Row>
        </Col>
      </Row>
      {/* MOBILE RESPONSIVE */}
      <Row>
        <Col md={{ span: 0, offset: 0 }} xs={{ span: 24 }}>
          <Row justify='end' style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Select defaultValue='vehicle' onChange={(value) => setViewBy(value)} value={viewBy} style={{ width: 150, marginRight: 20 }}>
                <Option value='vehicle'>Vehicle Model</Option>
                <Option value='region'>Region</Option>
              </Select>
            </Col>
            <Col span={12}>
              <Row justify='end'>
                <Button type='primary' onClick={onRefreshHandler}>
                  {motion.refresh ? <LoadingOutlined /> : <ReloadOutlined />}
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <CSSTransition in={cars.length !== 0} timeout={300} classNames='fade'>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span:24 }} style={{ position: 'relative' }}>
            {table}
          </Col>
        </Row>
      </CSSTransition>
    </Content>
  );
};

export default AllVehicle;