import React from 'react'
import * as _ from 'underscore';

import { Table } from 'antd'

import { nameColumn, 
         priceColumn, 
         carPlateColumn, 
         regionColumn, 
         addressColumn, 
         deckColumn, 
         lotNoColumn,
         noOfVehiclesModelColumn } from './helper';

const VehicleTable = ({ cars, breakpoint }) => {
  return (
    <Table 
        tableLayout="fixed" 
        style={{ cursor: 'pointer' }} 
        bordered 
        dataSource={_.unique(cars, 'vehicleName')} 
        columns={mainTableColumnsVehicle(cars, breakpoint)} 
        expandable={{expandedRowRender: subTableVehicleFn(cars), expandRowByClick: true}} />
  )
};

// Tables 
const mainTableColumnsVehicle = (cars, breakpoint) => [
  nameColumn,
  priceColumn,
  noOfVehiclesModelColumn(cars, breakpoint)
];

const subTableColumnsVehicle = [
  carPlateColumn,
  {...regionColumn, align: 'center', },
  addressColumn,
  deckColumn,
  lotNoColumn
];

const subTableVehicleFn = cars => (record) => {
  const modelCode = record.vehicleName;
  return (
    <Table 
      columns={subTableColumnsVehicle} 
      dataSource={cars.filter(car => car.vehicleName === modelCode)} 
      pagination 
      scroll={{ x: 200 }}
    />
  );
};

export { mainTableColumnsVehicle, subTableColumnsVehicle, subTableVehicleFn };
export default VehicleTable