import React from 'react'
import { Button } from 'antd'
import { CarOutlined, SearchOutlined } from '@ant-design/icons'
import { RiGasStationLine } from 'react-icons/ri';

const ControlPanel = ({ 
  searchControl,
  setControlVisibility,
  vehicleControl, 
  vehicleFilter, 
  setVehicleVisibility, 
  timeControl, 
  setTimeVisibility,
  petrolVisibility,
  setPetrolVisibility
}) => {

  const onSearchControlClick = () => {
    setVehicleVisibility(false)
    setTimeVisibility(false)
    setControlVisibility(!searchControl)
  }

  const onVehicleControlClick = () => {
    setControlVisibility(false)
    setTimeVisibility(false)
    setVehicleVisibility(!vehicleControl)
  }

  const carBtnType = () => {
    if (vehicleFilter && (vehicleFilter.name || vehicleFilter.category !== 'all')) {
      return 'primary'
    } else {
      if (vehicleControl) return 'default'
    }
  }

  return (
    <div style={{ backgroundColor: '#FFF', borderRadius: 10, padding: 10, position: 'absolute', top: 0, left: 0, margin: 15 }}>
      <Button icon={<SearchOutlined />} shape='round' onClick={onSearchControlClick} />
      <Button 
        icon={<CarOutlined />} 
        shape='round' 
        type={carBtnType()} 
        onClick={onVehicleControlClick} 
        style={{ marginLeft: 5 }} />
      <Button 
        icon={<RiGasStationLine style={{ verticalAlign: 'middle', fontSize: 19 }} />} 
        type={petrolVisibility ? 'primary' : 'default'}
        onClick={() => setPetrolVisibility(!petrolVisibility)}
        shape='round' 
        style={{ marginLeft: 5 }} />
    </div>
  )
}

export default ControlPanel