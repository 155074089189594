import React, { useState } from 'react'
import { Select, Radio } from 'antd'
import _ from 'underscore';

const { Option } = Select

const VehicleControl = ({ cars, vehicleFilter, setVehicleFilter }) => {

  const [category, setCategory] = useState('all')
  const [brandOption, setBrandOption] = useState(vehicleFilter && vehicleFilter.name ? vehicleFilter.name.split(" ")[0] : undefined)
  const [modelOption, setModelOption] = useState(vehicleFilter && vehicleFilter.name ? vehicleFilter.name.split(" ")[1] : undefined)

  const brands = _.uniq(cars.map(car => _.first(car.vehicleName.split(" ")))
                              .sort((a, b) => a.localeCompare(b)))
                              .map(brand => (<Option key={brand}>{brand}</Option>))

  const models = _.uniq(cars.filter(car => car.vehicleName.includes(brandOption))
                              .map(car => (car.vehicleName.split(' '))[1])
                              .sort((a, b) => a.localeCompare(b)))
                              .map(model => (<Option key={model}>{model}</Option>))

  const onBrandChange = (value) => {
    setBrandOption(value)
    setModelOption()
    if (value) setVehicleFilter({...vehicleFilter, name: value})
  }

  const onBrandClear = () => {
    setVehicleFilter()
  }

  const onModelChange = (value) => {
    setModelOption(value)
    setVehicleFilter({...vehicleFilter, name: `${brandOption} ${value}`})
  }

  const onCategoryChange = (e) => {
    setCategory(e.target.value)
    setVehicleFilter({name: vehicleFilter ? vehicleFilter.name : undefined, category: e.target.value})
  }

  return (
    <React.Fragment>
      <div className='panel-container-nobg'>
        <Radio.Group defaultValue={category} buttonStyle='solid' onChange={onCategoryChange}>
          <Radio.Button value='all'>All</Radio.Button>
          <Radio.Button value='saver'>Saver</Radio.Button>
          <Radio.Button value='standard'>Standard</Radio.Button>
          <Radio.Button value='plus'>Plus</Radio.Button>
        </Radio.Group>
      </div>
      <div className='panel-container-2'>
        <Select 
          onChange={onBrandChange}
          onClear={onBrandClear}
          placeholder='Brand' 
          bordered={false} 
          allowClear={true} 
          style={{ width: 100 }} 
          value={brandOption}>
          {brands}
        </Select>
        <Select 
          onChange={onModelChange}
          placeholder='Model' 
          bordered={false} 
          style={{ width: 150 }} 
          value={modelOption}>
          {models}
        </Select>
      </div>
    </React.Fragment>
  )
}

export default VehicleControl