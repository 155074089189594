import React from 'react'
import Carplate from '../../Carplate'
import RegionTag from '../../RegionTag';
import { Skeleton } from 'antd'

import { parsePrice } from '../../../utils/shariot';

export const renderCarPlateFn = (text, record) => {
  const S3BaseUrl = 'https://shariot-file-storage-prod.s3-ap-southeast-1.amazonaws.com/';

  const onImageErrorHandler = (event) => {
    event.target.outerHTML = '';
  };

  return (
    <React.Fragment>
      <div style={{ width: '125px', textAlign: 'center' }}>
        { record.photoKey !== 'fake-photo' ? 
          <img 
            className='car-image' 
            src={S3BaseUrl + record.photoKey} 
            alt={record.vehicleName} 
            onError={onImageErrorHandler} 
            style={{ width: '125px', height: 'auto', display: 'block' }} /> 
          : <Skeleton.Image /> }
        <Carplate number={record.plateNumber} />
      </div>
    </React.Fragment>
  );
};

// columns

export const noOfVehiclesModelColumn = (cars, breakpoint) => {
  return {
    title: 'No. of vehicles', 
    width: breakpoint.md || breakpoint.lg || breakpoint.xl ? 150 : 100,
    key: 'noOfVehicle', 
    render: (text, record) => cars.filter(car => car.vehicleName === record.vehicleName).length,
    sorter: (a, b) => cars.filter(car => car.vehicleName === a.vehicleName).length - cars.filter(car => car.vehicleName === b.vehicleName).length
  };
};

export const noOfVehiclesRegionColumn = (cars, breakpoint) => {
  return {
    title: 'No. of vehicles', 
    width: breakpoint.md || breakpoint.lg || breakpoint.xl ? 150 : 100,
    key: 'noOfVehicle', 
    render: (text, record) => cars.filter(car => car.region === record.region).length
  };
};

export const nameColumn = {
  title: 'Name', 
  dataIndex: 'vehicleName', 
  key: 'vehicleName', 
  defaultSortOrder: 'ascend', 
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  render: (text, record) => <a href="#">{text}</a>, 
  sorter: (a, b) => a.vehicleName.localeCompare(b.vehicleName)
};

export const regionColumn = {
  title: 'Region',
  key: 'region',
  sorter: (a, b) => a.region.localeCompare(b.region),
  render: (_, record) => (<RegionTag region={record.region} />)
};

export const priceColumn = {
  title: 'Price',
  dataIndex: 'category',
  key: 'vehiclePrice',
  render: (text, record) => parsePrice(text),
  sorter: (a, b) => a.categoryId - b.categoryId
};

export const carPlateColumn = {
  title: 'Car', 
  dataIndex: 'plateNumber', 
  key: 'plateNumber', 
  render: renderCarPlateFn
};

export const addressColumn = {
  title: 'Address', 
  key: 'address', 
  sorter: (a, b) => {
    return a.vehicleCarPark.carPark.address.postalCode - b.vehicleCarPark.carPark.address.postalCode;
  },
  render: (text, record) => record.vehicleCarPark.carPark.address.detailAddress
};

export const deckColumn = {
  title: 'Deck', 
  key: 'deck', 
  align: 'center',
  render: (text, record) => record.deckNo
};

export const lotNoColumn = {
  title: 'Lot No.', 
  key: 'lotno', 
  align: 'center',
  render: (text, record) => record.lotNo
};