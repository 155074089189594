import React from 'react';
import { Typography, Layout, Timeline, Row, Col } from 'antd';

const { Title } = Typography;
const { Content } = Layout;

const Changelog = () => {
  return (
    <Content style={{ padding: '20px 30px' }}>
      <Title>Changelog</Title>
      <Row style={{ paddingTop: 20 }}>
        <Col span={24}>
        <Timeline mode='left'>
          <Timeline.Item>
            <Title level={5}>28 May 2021</Title>
            <p>Bug Fix: Reloading of data when switching between Home/Map pages</p>
            <p>Bug Fix: (Map) Remove zooming out when exiting carpark card</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>15 Feb 2021</Title>
            <p>Bug Fix: Issue of zoom-in when typing location on mobile</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>11 Feb 2021</Title>
            <p>Added: Map: Filter through vehicle brand/model</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>10 Feb 2021</Title>
            <p>Bug Fix: Vehicle image out of alignment with car plate</p>
          </Timeline.Item>
          <Timeline.Item>
              <Title level={5}>7 Feb 2021</Title>
              <p>Added: Map: Automatically launch nearest carpark card upon search</p>
              <p>Added: Map: Hover vehicle name to check category</p>
              <p>Added: Map: Filter vehicle class</p>
            </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>6 Feb 2021</Title>
            <p>Added: Link from Shariot Explorer Map to Google Map's Direction</p>
            <p>Bug Fix: Vehicle overflow issue when &gte;4 vehicles</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>8 Jan 2021</Title>
            <p>Data Fix: Honda Fit (SMW8177X) photo changed. Thanks to&nbsp;
              <a href='https://www.facebook.com/groups/shariotsg/permalink/1048087818990450/' target='_blank' rel='noreferrer'>
                Chang Chuan Low 
              </a>
              &nbsp;from Shariot Spottings Singapore FB Group</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>7 Jan 2021</Title>
            <p>Bug Fix: 'Price' (Category) added to Region</p>
            <p>Bug Fix: Added Vehicle Name to Region</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>29 December 2020</Title>
            <p>Rolled out Map (beta phase)</p>
            <p>Added 'Price' (Category) to All Vehicle Table</p>
          </Timeline.Item>
          <Timeline.Item>
            <Title level={5}>25 December 2020</Title>
            <p>Added car photo image feature</p>
            <p>Added 'View By' with Vehicle Model &amp; Region options</p>
          </Timeline.Item>
          <Timeline.Item color='green'>
            <Title level={5}>24 December 2020</Title>
            Launched Shariot Explorer on Shariot Spottings Group Facebook Page
          </Timeline.Item>
        </Timeline>
        </Col>
      </Row>
      </Content>
  );
};

export default Changelog;