import axios from 'axios';
import moment from 'moment';

import React from 'react';
import { DollarTwoTone } from '@ant-design/icons';

import { getRegion } from '../utils/location';

export const getLocalShariotData = () => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject('Axios request timeout!')
    }, 20000);

    axios.get('https://5mclssrz46.execute-api.ap-southeast-1.amazonaws.com/dev/shariot').then(res => {
      clearTimeout(timeout);
      resolve(res.data)
    }, err => {
      reject(err)
    })
  })
}

export const getShariotData = () => {
  const payload = {
    latitude: 1.363702, // center of sg
    longtitude: 103.796656, // center of sg
    startTime: moment().add('3', 'M').set('hour', 4).set('minute', 0).set('second', 0).toISOString(),
    endTime: moment().add('3', 'M').set('hour', 5).set('minute', 0).set('second', 0).toISOString(),
    categoryUuid: '8d8889a0-abb4-11ea-9066-ffe9c5705912',
    radius: 100
  };

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject('Axios request timeout!')
    }, 20000);
    axios.get('https://apiv2.shariot.com/vehicle/getAvailable', { params: payload })
    .then(res => {
      const cars = res.data.data;
      // console.log(cars);
      cars.forEach(car => {
        car.key = car.id;
        if (car.deckNo === '') car.deckNo = '-';
        car.deckNo = car.deckNo.toUpperCase();

        if (car.vehicleName.trim() === 'Honda Vezel Petrol') car.vehicleName = 'Honda Vezel'
        if (car.vehicleModel.modelCode.trim() === 'Honda Vezel Petrol') car.vehicleModel.modelCode = 'Honda Vezel'

        if (car.vehicleName.trim().toLowerCase() === 'honda vezel hybrid') car.vehicleName = 'Honda Vezel Hybrid'
        if (car.vehicleModel.modelCode.trim().toLowerCase()  === 'honda vezel hybrid') car.vehicleModel.modelCode = 'Honda Vezel Hybrid'
        
        if (car.vehicleName.trim().toLowerCase() === 'toyota wish') car.vehicleName = 'Toyota Wish'
        if (car.vehicleModel.modelCode.trim().toLowerCase()  === 'toyota wish') car.vehicleModel.modelCode = 'Toyota Wish'

        if (car.vehicleName.trim() === 'Toyota CHR Hybrid') car.vehicleName = 'Toyota C-HR Hybrid'
        if (car.vehicleModel.modelCode.trim() === 'Toyota CHR Hybrid') car.vehicleModel.modelCode = 'Toyota C-HR Hybrid'

        if (car.vehicleName.trim() === 'Toyota’s Prius Hybird') car.vehicleName = 'Toyota Prius Hybrid'
        if (car.vehicleModel.modelCode.trim() === 'Toyota’s Prius Hybird') car.vehicleModel.modelCode = 'Toyota Prius Hybrid'

        car.vehicleName = car.vehicleName.replace('Super Saver', '');

        if (car.price === 1) {
          car.category = 'Saver';
          car.categoryId = 1;
        } 

        if (car.price === 2.5) {
          car.category = 'Standard';
          car.categoryId = 2;
        } 

        if (car.price === 4.5) {
          car.category = 'Plus';
          car.categoryId = 3;
        }
        
        // REGION
        car.region = getRegion(car.vehicleCarPark.carPark.address.postalCode);

        // rectify incorrect photos
        if (car.plateNumber === 'SMW8177X') car.photoKey = 'images/vehicle_photo/vehicle_image_1609747106400Honda-Fit-Lunar-Silver-Metallic.png';

        // misc clean up
        car.vehicleName = car.vehicleName.trim();
      });
      clearTimeout(timeout);
      resolve(cars);
    }, err => {
      reject(err);
    });
  });
};

export const parsePrice = (category) => {
  switch (category) {
    case 'Saver':
      return (<DollarTwoTone />);
    case 'Standard':
      return (<React.Fragment><DollarTwoTone /> <DollarTwoTone /></React.Fragment>);
    case 'Plus':
      return (<React.Fragment><DollarTwoTone /> <DollarTwoTone /> <DollarTwoTone /></React.Fragment>);
    default:
      break;
  }
};