import React, { useState } from 'react'
import { Select } from 'antd'
import { getLocationCoords } from '../../../utils/onemap';

const { Option } = Select;

const SearchControl = ({ viewport, setViewport, setSearchLocation }) => {

  const [searchResults, setSearchResults] = useState([]);

  const searchHandler = (value) => {
    getLocationCoords(value).then(results => {
      setSearchResults(results);
    }, err => {
      console.log(err);
    });
  };

  const searchChangeHandler = (value) => {
    if (value) {
      let [latitude, longitude] = value.split(',');
      latitude = parseFloat(latitude)
      longitude = parseFloat(longitude)
      setViewport({ center: [longitude, latitude], zoom: [16] })
      setSearchLocation([longitude, latitude])
    }
  }

  const searchClear = () => {
    setViewport({ center: [103.80292997363502, 1.3432443317157046], zoom: [11] })
    setSearchLocation([])
  }

  const searchOptions = searchResults.map(searchResult => {
    const {BLK_NO, ROAD_NAME, BUILDING, ADDRESS, POSTAL, LATITUDE, LONGITUDE} = searchResult;
    const key = `${LATITUDE},${LONGITUDE}`;
    let result;

    if (BUILDING !== 'NIL') {
      result = (
        <Option key={ADDRESS} value={key}>
          <h3 style={{marginBottom: 0}}>{BUILDING}</h3>
          {BLK_NO} {ROAD_NAME} SINGAPORE {POSTAL}
        </Option>
      );
    } else {
      result = (
        <Option key={ADDRESS} value={key}>
          <h3 style={{marginBottom: 0}}>{BLK_NO} {ROAD_NAME}</h3>
          SINGAPORE {POSTAL}
        </Option>
      )
    }

    return result;
  });

  return (
    <div className='panel-container' style={{ width: '25%' }}>
      <Select
        showSearch
        allowClear
        placeholder='Enter location'
        onSearch={searchHandler}
        onChange={searchChangeHandler}
        onClear={searchClear}
        filterOption={false}
        bordered={false}
        style={{ width: '100%' }}>
          {searchOptions}
      </Select>
    </div>
  )
}

export default SearchControl