export const capitalize = (word) => {
  word = word.toLowerCase();
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const gMapLinkParser = (opts) => {
  switch (opts.count) {
    case 1:
      const { latitude, longitude } = opts.locations[0];
      return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    case 2:
      const { latitude: originLat, longitude: originLng } = opts.locations[0];
      const { latitude: destLat, longitude: destLng } = opts.locations[1];
      return `https://www.google.com/maps/dir/?api=1&origin=${originLat},${originLng}&destination=${destLat},${destLng}`;
    default:
      break;
  }
}

export const distanceBetweenTwo = (latitude0, longitude0, latitude1, longitude1) => {
  // using pythagoras theorem
  const d_ew = (longitude1 - longitude0) * Math.cos(latitude0);
  const d_ns = (latitude1 - latitude0);
  return Math.sqrt(d_ew * d_ew + d_ns * d_ns);
};

export const toRad = (degrees) => {
  return degrees * (Math.PI / 180);
};

export const toDeg = (rad) => {
  return rad * (180 / Math.PI);
};

export const getMidpoint = (lat1, lng1, lat2, lng2) => {
  //-- Longitude difference
  var dLng = toRad(lng2 - lng1);

  //-- Convert to radians
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);
  lng1 = toRad(lng1);

  var bX = Math.cos(lat2) * Math.cos(dLng);
  var bY = Math.cos(lat2) * Math.sin(dLng);
  var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
  var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

  //-- Return result
  return { latitude: toDeg(lat3), longitude: toDeg(lng3) };
}