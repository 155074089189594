import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import {  BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import 'antd/dist/antd.css';

import { Layout, message } from 'antd';
import Navbar from './components/Navbar';
import AllVehicle from './components/AllVehicle/AllVehicle';
import Changelog from './components/Changelog';
import Vehicle from './components/Vehicle';

import { getLocalShariotData } from './utils/shariot';
import MapLayerTest from './components/Map/MapLayerTest';

const { Footer } = Layout;

const App = () => {

  const [loaded, setLoaded] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [cars, setCars] = useState([]);

  const shariotDataHandler = () => {
    return new Promise((resolve, reject) => {
      getLocalShariotData().then(cars => {
        setCars(cars);
        resolve(cars);
      }, err => {
        console.log(err);
        message.error('There has been an issue retrieving the data, try again!');
        reject(err);
      })
    });
  };

  const loadCarsHandler = async () => {
    setLoaded(false);
    setCars([]);
    await shariotDataHandler();
    setLoaded(true);
  }

  useEffect(() => {
    async function execute() {
      await loadCarsHandler();
    }
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <CSSTransition in={loaded} timeout={300} classNames='fade'>
        <Layout className="layout" style={{minHeight: '100vh'}}>
          <Navbar 
            loginData={loginData}
            setLoginData={setLoginData}/>
          <Switch>
            <Route path={['/shariot/vehicle', '/vehicle']}>
              <Vehicle />
            </Route>
            <Route path={['/shariot/changelog', '/shariot/dev/changelog', '/changelog']} component={Changelog} />
            <Route path={['/shariot/map', '/shariot/dev/map', '/map']}>
              {/* <Map 
                cars={cars} /> */}
              <MapLayerTest cars={cars} />
              {/* <MapTest cars={cars} coordinates={{ lat: 1.3432443317157046, lng: 103.80292997363502 }} /> */}
            </Route>
            <Route path={['/shariot', '/shariot/', '/shariot/dev', '/', `/${process.env.REACT_APP_BRANCH}` || '/']}>
              <AllVehicle 
                cars={cars} 
                refreshCarHandler={loadCarsHandler} />
            </Route>
          </Switch>
          <Footer><small>samleewy.com | &copy; 2020-2021. Not affiliated with <a href='https://shariot.com' target='_blank' rel='noreferrer'>Shariot</a>.</small></Footer>
        </Layout>
      </CSSTransition>
    </Router>
  );
}

export default App;