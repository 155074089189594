import React from 'react'
import { DatePicker } from 'antd'
const { RangePicker } = DatePicker

const TimeControl = () => {
  return (
    <div className='panel-container'>
      <RangePicker 
        bordered={false}
        showTime={{ format: 'HH:mm' }}
        format="YYYY-MM-DD HH:mm"
        minuteStep={15}/>
    </div>
  )
}

export default TimeControl