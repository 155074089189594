import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Modal, Typography, Row, Col, Tag } from 'antd';

const { Header } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

const Navbar = (props) => {

  const [modal, setModal] = useState(false);

  const getSelectedKeys = () => {
    switch (props.location.pathname) {
      case '/shariot/':
      case '/shariot/dev/':
      case '/shariot':
      case '/shariot/dev':
      case '/':
        return ['home']
      case '/shariot/changelog':
      case '/shariot/dev/changelog':
      case '/shariot/changelog/':
      case '/shariot/dev/changelog/':
      case '/changelog':
        return ['about', 'changelog']
      case '/shariot/map':
      case '/shariot/map/':
      case '/shariot/dev/map':
      case '/shariot/dev/map/':
      case '/map':
        return ['map']
      default:
        return;
    }
  };

  return (
  <Header style={{padding: '0 30px'}}>
    <Row>
      <Col md={{ span: 12 }} xs={{ span: 18 }}>
        <div className="logo">
          <span style={{fontWeight: '800', paddingRight: '6px'}}>Shariot Explorer</span> 
          <small onClick={() => setModal(true)} style={{ cursor: 'pointer' }}>by sam</small>
        </div>
      </Col>
      <Col md={{ span: 12 }} xs={{ span: 6 }}>
        <Menu theme='dark' mode='horizontal' selectedKeys={getSelectedKeys()} style={{ float: 'right' }} >
          <Menu.Item key='home'>
            <Link to={`./`}>Home</Link>
          </Menu.Item>
          <Menu.Item key='map'>
            <Link to={`./map`}>Map <Tag color='green' style={{ marginLeft: 5 }}>BETA</Tag></Link>
          </Menu.Item>
          <SubMenu title="About" key='about'>
            <Menu.Item key='creator' onClick={() => setModal(true)}>
              Creator
            </Menu.Item>
            <Menu.Item key='changelog'>
              <Link to={`./changelog`}>Changelog</Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Col>
    </Row>
    <Modal title='Who Am I?' style={{ top: 150 }} visible={modal} onOk={() => setModal(false)} onCancel={() => setModal(false)}>
      <Title level={2}>Samuel Lee</Title>
      <p>
        Hello! I'm currently a Computer Science undergraduate who's interested in web development! Shariot Explorer happens to
        be one of my passion projects. I strongly believe in using web applications to solve real life problems! Feel free to
        take a look at my other works @ <a href='https://www.samleewy.com'>samleewy.com</a>. If you need a full stack web developer,
        do hit me up as well! :)
      </p>
    </Modal>
  </Header>
  );
};

export default withRouter(Navbar);