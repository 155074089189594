import axios from 'axios';

export const getPetrolStations = () => {
  return new Promise((resolve, reject) => {
    axios.get('https://5mclssrz46.execute-api.ap-southeast-1.amazonaws.com/dev/petrolStation').then(res => {
      resolve(res.data)
    }, err => {
      reject(err)
    })
  })
}