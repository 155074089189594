import React from 'react'
import { Spin } from 'antd'
import './LoadingOverlay.css'

const LoadingOverlay = () => {
  return (
    <div className='loading-container'>
      <div className='loading-spinner'>
          <Spin style={{ marginTop: 5 }} />
      </div>
    </div>
  )
}

export default LoadingOverlay