import React, { useEffect, useState } from 'react'
import mapboxgl, { GeolocateControl } from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import ReactMapboxGl, { Feature, Layer } from 'react-mapbox-gl';
import _ from 'underscore'

import './MapLayerTest.css'

import LoadingOverlay from './LoadingOverlay/LoadingOverlay'
import VehicleControl from './VehicleControl/VehicleControl';
import TimeControl from './TimeControl/TimeControl'
import ControlPanel from './ControlPanel/ControlPanel';
import MapCard from '../Map/MapCard'
import SearchControl from './SearchControl/SearchControl';

import PetrolFeature from './PetrolFeature/PetrolFeature';

// petrol station logos
import spcLogo from '../../images/spc.png';
import essoLogo from '../../images/esso.png';
import shellLogo from '../../images/shell.png';
import caltexLogo from '../../images/caltex.png';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Mapbox = ReactMapboxGl({
  minZoom: 8,
  maxZoom: 20,
  accessToken: 'pk.eyJ1Ijoic2FtbGVld3kiLCJhIjoiY2tjenFocnJkMG04bjMwbjd6OHFjb2k2dSJ9.a4TYbB40yvqQTW9aeAz74g'
})

const MapLayerTest = ({ cars }) => {

  // mapbox states 
  // const [center, setCenter] = useState([103.80292997363502, 1.3432443317157046])
  // const [zoom, setZoom] = useState([11])
  const [viewport, setViewport] = useState({
    center: [103.80292997363502, 1.3432443317157046],
    zoom: [11]
  })

  // control states
  const [searchControl, setSearchControl] = useState(false)
  const [searchLocation, setSearchLocation] = useState([])

  const [vehicleControl, setVehicleControl] = useState(false)
  const [vehicleFilter, setVehicleFilter] = useState()

  const [timeControl, setTimeControl] = useState(false)

  const [petrolVisibility, setPetrolVisibility] = useState(false)

  // cars
  const [card, setCard] = useState({ carpark: {}, visibility: false });

  // effects
  useEffect(() => {
    const defaultCenter = [103.80292997363502, 1.3432443317157046]
    if (!card.visibility) setViewport({ center: defaultCenter, zoom: [11] })
  }, [card])

  // functions
  const onFeatureClick = (carpark) => {
    setViewport({ center: [carpark.address.longtitude, carpark.address.latitude], zoom: [16] })
    setCard({ carpark, visibility: true });
  }

  let displayedCars = cars

  // filter
  if (vehicleFilter && vehicleFilter.name) displayedCars = displayedCars.filter(car => car.vehicleName.includes(vehicleFilter.name))
  if (vehicleFilter) {
    if (vehicleFilter.category === 'saver') {
      displayedCars = displayedCars.filter(car => car.category === 'Saver')
    } else if (vehicleFilter.category === 'standard') {
      displayedCars = displayedCars.filter(car => car.category === 'Standard')
    } else if (vehicleFilter.category === 'plus') {
      displayedCars = displayedCars.filter(car => car.category === 'Plus')
    }
  }

  const carparkIDs = displayedCars.map(car => car.vehicleCarPark.carPark);
  const uniqueCarparkIDs = _.uniq(carparkIDs, carpark => carpark.uuid);

  const carFeatures = uniqueCarparkIDs.map(carpark => {
    const { latitude, longtitude: longitude } = carpark.address;
    const carCount = displayedCars.filter(carFilter => {
      const {latitude: filterLatitude, longtitude: filterLongitude} = carFilter.vehicleCarPark.carPark.address;
      return filterLatitude === latitude && filterLongitude === longitude;
    }).length;
  
    return (
      <Feature 
        onClick={() => onFeatureClick(carpark)}
        key={carpark.uuid} 
        coordinates={[longitude, latitude]} 
        properties={{carCount}} />
    )
  }) || [];

  const carFeaturesLabel = uniqueCarparkIDs.map(carpark => {
    const { latitude, longtitude: longitude } = carpark.address;
    const carCount = displayedCars.filter(carFilter => {
      const {latitude: filterLatitude, longtitude: filterLongitude} = carFilter.vehicleCarPark.carPark.address;
      return filterLatitude === latitude && filterLongitude === longitude;
    }).length;
  
    return (
      <Feature 
        key={carpark.uuid} 
        coordinates={[longitude, latitude]} 
        properties={{carCount}} />
    )
  }) || [];

  // mapbox variables
  // style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
  const mapBoxProps = {
    center: viewport.center,
    zoom: viewport.zoom,
    containerStyle: { height: 'calc(100vh - 64px)' },
    style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
    onStyleLoad: (map) => {
      setViewport({ center: viewport.center, zoom: [11.000] })
      map.addControl(new GeolocateControl())
      map.loadImage(spcLogo, (err, img) => {
        map.addImage('spc-icon', img);
      });
      map.loadImage(essoLogo, (err, img) => {
        map.addImage('esso-icon', img);
      });
      map.loadImage(caltexLogo, (err, img) => {
        map.addImage('caltex-icon', img);
      });
      map.loadImage(shellLogo, (err, img) => {
        map.addImage('shell-icon', img);
      });
    },
    flyToOptions: { speed: 1.2 }
  }

  const mapBoxLayerPaint = {
    'circle-color': '#00ff8c',
    'circle-radius': 10,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#fff',
    'circle-stroke-opacity': 1
  };

  const mapBoxSearchMarkerPaint = {
    'circle-color': '#13c2c2',
    'circle-radius': 5,
    'circle-stroke-width': 2,
    'circle-stroke-color': '#fff',
    'circle-stroke-opacity': 1
  };

  const mapBoxLayerLayout = {
    'text-field': '{carCount}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 10
  }

  return (
    <Mapbox {...mapBoxProps}>
      <Layer type='circle' id='marker' paint={mapBoxLayerPaint}>
        {carFeatures}
      </Layer>
      <Layer type='symbol' layout={mapBoxLayerLayout}>
        {carFeaturesLabel}
      </Layer>
      <Layer type='circle' id='searchMarker' paint={mapBoxSearchMarkerPaint}>
        <Feature 
          key='searchMarker'
          coordinates={searchLocation}/>
      </Layer>
      { petrolVisibility && <PetrolFeature /> }
      { !cars.length > 0 && <LoadingOverlay /> }
      <ControlPanel 
        searchControl={searchControl}
        setControlVisibility={setSearchControl}
        vehicleControl={vehicleControl}
        vehicleFilter={vehicleFilter}
        setVehicleVisibility={setVehicleControl}
        timeControl={timeControl}
        setTimeVisibility={setTimeControl}
        petrolVisibility={petrolVisibility}
        setPetrolVisibility={setPetrolVisibility} />
      {searchControl && 
        <SearchControl 
          viewport={viewport} 
          setViewport={setViewport} 
          setSearchLocation={setSearchLocation} />}
      {vehicleControl && <VehicleControl vehicleFilter={vehicleFilter} setVehicleFilter={setVehicleFilter} cars={cars} />}
      {timeControl && <TimeControl />}
      {card.visibility ? <MapCard
        cars={displayedCars}
        carpark={card.carpark}
        card={card}
        setCard={setCard} /> : ''}
    </Mapbox>
  )
}

export default MapLayerTest