import axios from 'axios';

export const getLocationCoords = (searchVal) => {
  if (searchVal === '') return Promise.resolve([]);
  return new Promise((resolve, reject) => {
    axios.get('https://developers.onemap.sg/commonapi/search', { params: { searchVal: searchVal, returnGeom: 'Y', getAddrDetails: 'Y' } })
    .then(res => {
      resolve(res.data.results);
    }, err => {
      reject(err);
    });
  });
};

export const getRoute = (start, end) => {
  return new Promise((resolve, reject) => {
    axios.post('https://5mclssrz46.execute-api.ap-southeast-1.amazonaws.com/dev/route', { start, end }).then(res => {
      resolve(res.data);
    }, err => {
      console.log(err);
      reject(err);
    });
  });
};