import React from 'react';
import { Layout, Typography, PageHeader, Descriptions, Avatar, Tag, Badge, Tabs, Comment, Divider, Carousel } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Content } = Layout;
const { Title } = Typography;

const Vehicle = () => {
  return (
    <Content style={{ padding: '20px 30px'}}>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={
          (<React.Fragment>
            <img alt='vehicle' style={{ width: 125, height: 'auto', borderRadius: '50%' }} src='https://shariot-file-storage-prod.s3-ap-southeast-1.amazonaws.com/images/vehicle_photo/vehicle_image_1608745095468TOYOTA-CHR-2018-Metal-Stream-Metallic.png' /> 
            Toyota C-HR
          </React.Fragment>)}
        subTitle="807 CHAI CHEE ROAD S(460807)">
          <Descriptions bordered>
            <Descriptions.Item label='Car Plate'>
              <Tag color='#333333' style={{fontFamily: 'LicensePlate', fontSize: '1rem', marginLeft: '0px', marginTop: '5px'}}>
                SMW1793X
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label='Full Address'>
              <Tag color='green'>EAST</Tag> 807 CHAI CHEE ROAD S(460807)
              5A 288
            </Descriptions.Item>
            <Descriptions.Item label='Status'>
              <Badge status="processing" text="Normal" />
            </Descriptions.Item>
            <Descriptions.Item label='Deck No.'>
              5A
            </Descriptions.Item>
            <Descriptions.Item label='Lot No.'>
              288
            </Descriptions.Item>
            <Descriptions.Item label='Mileage'>
              No Data <small>contribute?</small>
            </Descriptions.Item>
          </Descriptions>
      </PageHeader>
      <div style={{backgroundColor: 'white', padding: '16px 24px'}}>
        <Title level={3}>Contributed Images</Title>
        <Tabs style={{minHeight: '350px'}}>
          <Tabs.TabPane tab='Schedule' key='schedule'>
            test
          </Tabs.TabPane>
          <Tabs.TabPane tab='Reviews' key='reviews'>
            <Comment
              avatar={<Avatar size={32} icon={<UserOutlined />} />}
              author={"Samuel Lee"}
              content={
                <p>
                  Car was really well maintained and only had 3k mileage on it!
                </p>
              } />
          </Tabs.TabPane>
        </Tabs>
        <Divider orientation='left' >
          Cars in the same carpark
        </Divider>
        <Carousel autoplay={true}>
          <div>
            test
          </div>
          <div>
            test 2
          </div>
        </Carousel>
        <Divider orientation='left' >
          Other car locations
        </Divider>
      </div>
    </Content>
  );
};

export default Vehicle;