import React from 'react'
import { Tag } from 'antd'

const Carplate = ({ number }) => {
  return (
    <Tag color='#333333' style={{fontFamily: 'LicensePlate', fontSize: '1rem', marginLeft: '0px', marginTop: '5px'}}>
      {number}
    </Tag>
  )
};

export default Carplate