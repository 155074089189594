import React from 'react'
import * as _ from 'underscore';

import { Table } from 'antd'

import { nameColumn, 
         priceColumn, 
         carPlateColumn, 
         regionColumn, 
         addressColumn, 
         deckColumn,
         lotNoColumn, 
         noOfVehiclesRegionColumn } from './helper';

const RegionTable = ({ cars, breakpoint }) => {
  return (
    <Table 
          tableLayout="fixed" 
          style={{ cursor: 'pointer' }} 
          bordered 
          dataSource={_.unique(cars, 'region')} 
          columns={mainTableColumnsRegion(cars, breakpoint)} 
          expandable={{expandedRowRender: subTableRegionFn(cars), expandRowByClick: true}} />
  )
}

const mainTableColumnsRegion = (cars, breakpoint) => [
  regionColumn,
  noOfVehiclesRegionColumn(cars, breakpoint)
];

const subTableColumnsRegion = [
  nameColumn,
  carPlateColumn,
  priceColumn,
  addressColumn,
  deckColumn,
  lotNoColumn
];

const subTableRegionFn = cars => (record) => {
  const region = record.region;
  return (
    <Table 
      columns={subTableColumnsRegion} 
      dataSource={cars.filter(car => car.region === region)} 
      pagination 
      scroll={{x: 200}} 
    />
  );
};

export { mainTableColumnsRegion, subTableColumnsRegion, subTableRegionFn }
export default RegionTable